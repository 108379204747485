import { render, staticRenderFns } from "./PwdResetForm.vue?vue&type=template&id=894587da&scoped=true&"
import script from "./PwdResetForm.vue?vue&type=script&lang=js&"
export * from "./PwdResetForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "894587da",
  null
  
)

export default component.exports