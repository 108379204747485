<template>
    <div>
        <ValidationObserver ref="form" tag="div"  class="">
            <div v-if="notFound" class="mb-4 flex justify-center items-end space-x-1.5">
                <span>
                    <svg width="27" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.1278 20.3356h.0002L15.7264 4.2105h-.0002a2.685 2.685 0 00-4.64 0h-.0003L1.7074 20.2959l-.001.0017-.022.0379h.0002a2.6873 2.6873 0 002.32 4.0416h18.8032a2.6873 2.6873 0 002.32-4.0415zm-7.5668-.3134a1.1048 1.1048 0 01-1.5626 0l-2.5923-2.5936-2.5924 2.5936a1.1053 1.1053 0 01-1.8563-.4923 1.1053 1.1053 0 01.294-1.0711l2.5923-2.5933-2.5001-2.5014c-.427-.4323-.4248-1.1282.0047-1.5579.4296-.4297 1.1254-.4321 1.5579-.0054l2.5001 2.5013 2.5-2.5013a1.1054 1.1054 0 011.8571.4919 1.1049 1.1049 0 01-.2946 1.0714l-2.5 2.5014 2.5922 2.5932c.4314.4319.4314 1.1316 0 1.5635z" fill="#EB5757" />
                    </svg>
                </span>
                <span>Utilisateur inexistant</span>
            </div>
            <form @submit.prevent="onSubmit">
                <ValidationProvider rules="required|email" name = "L'adresse email" v-slot="{ errors }">
                    <div class="mb-6">
                        <m-input
                        :errors="errors[0]"
                        v-model="email"
                        name = "email"
                        label="E-mail"
                        >
                        <span slot="prepend"><svgicon name="mail" width="24" heigth="24"></svgicon></span>
                        </m-input>
                    </div>
                </ValidationProvider>
                <div class="">
                    <m-button :type="'submit'" :classes="'text-white font-bold text-sm sm:text-base bg-primary rounded-lg p-2 w-full'" load :activateLoad="request" :text="'Créer un nouveau mot de passe'"></m-button>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    props: {
        step: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            request: false, 
            email: '',
            notFound: false
        }
    },
    watch: {
        email(){
           this.notFound = false
        }
    },
    components: {

    },
    computed: {
        
    },
    methods: {
        onSubmit(){
            this.$refs.form.validateWithInfo().then(validate => {
                let { isValid, errors } = validate;
                console.log(isValid, errors)
                if(isValid) {
                    this.request = true
                    axios.post('/users/password/token',{
                        username: this.email
                    })
                    .then( res => {
                        this.request = false
                        this.$emit('mail', this.email)
                        this.$emit('stepUpdate', 2)
                        console.log(res)
                    })
                    .catch( err => {
                        this.request = false
                        console.log(err.response)
                        if (err.response.data.statusCode == 404) this.notFound = true
                    })
                }
            })
            
        }
    },
}
</script>

<style scoped>

</style>