<template>
    <div class="p-4 pt-10 md:pt-16">
        <div v-if="step == 1">
            <div class="bg-white border border-innerBorder rounded-2xl p-4 lg:p-8 md:w-2/3 xl:w-1/2 mx-auto">
                <p class="font-bold text-2xl mb-2">Réinitialiser votre mot de passe</p>
                <PwdResetForm :step="step" @stepUpdate="step = $event" @mail="email = $event"/>  
            </div>
            <div class="my-4 px-2 font-bold text-sm sm:text-base text-center">
                <p>Vous avez déjà un compte <router-link to="/login" class="text-blueLink underline">Se Connecter</router-link></p>
            </div>
        </div>
        <div v-if="step == 2">
            <div class="bg-white border border-innerBorder rounded-2xl p-4 lg:p-8 md:w-2/3 xl:w-1/2 mx-auto">
                <p class="font-bold text-2xl mb-2">Réinitialiser votre mot de passe</p>
                <p class="text-fonctGrey mb-6">Nous avons envoyé un code de réinitialisation à votre adresse email {{email}}</p>
                <PwdResetCodeForm :step="step" @code="code = $event" @stepUpdate="step = $event"/>  
            </div>
            <div class="my-4 px-2 font-bold text-sm sm:text-base text-center">
                <p>Vous n’avez pas reçu de code ? <span @click="resendToken" class="text-blueLink underline cursor-pointer">Renvoyer le code</span></p>
            </div>
        </div>
        <div v-if="step == 3">
            <div class="bg-white border border-innerBorder rounded-2xl p-4 lg:p-8 md:w-2/3 xl:w-1/2 mx-auto">
                <p class="font-bold text-2xl mb-2">Créer votre nouveau mot de passe</p>
                <PwdChangedForm :step="step" :email="email" :code="code" @stepUpdate="step = $event"/>  
            </div>
        </div>
    </div>
</template>

<script>
import PwdResetForm from './forms/PwdResetForm'
import PwdResetCodeForm from './forms/PwdResetCodeForm'
import PwdChangedForm from './forms/PwdChangedForm'
import axios from 'axios'
export default {
    data() {
        return {
            step: 1,
            email: '',
            code: ''
        }
    },
    components: {
        PwdResetForm,
        PwdResetCodeForm,
        PwdChangedForm
    },
    computed: {
    },
    methods: {
        resendToken(){
            axios.post('/users/password/token',{
                username: this.email
            })
            .then( res => {
                console.log(res)
            })
            .catch( err => {
                console.log(err.response)
            })
        }
    },
}
</script>

<style scoped>

</style>